/**
 * 제작 : HanSeungHyeon
 * 날짜 : 7/1/24
 * 내용 :
 */

import React, { useCallback, useEffect, useState } from 'react';
import SCROLL_CONTENT_LIST from '../../constants/scroll/scroll_content';
import useWindowSize from '../../components/hooks/useWindowSize';
import useScroll from '../../components/hooks/useScroll';
import useOpacityHandler from '../../components/hooks/useOpacityHandler';
import MainHeader from '../../components/header/MainHeader';
import Visual from '../../components/visual/Visual';
import OpacityBox from '../opacity/OpacityBox';
import AlignCenter from '../center/AlignCenter';
import Footer from '../../components/footer/Footer';
import Greeting from '../../components/greeting/Greeting';
import IconLogo from '../../components/icons/IconLogo';

function HomeContents() {
  const { vh } = useWindowSize();
  const { scrollPosition } = useScroll();
  const { y } = scrollPosition;
  const [opacity, setOpacity] = useState(0);
  const { increaseOpacity } = useOpacityHandler();

  const { decreasePoint } =
    SCROLL_CONTENT_LIST[SCROLL_CONTENT_LIST.length - 1].breakPoint;

  /**
   * 스크롤 이벤트 처리
   */
  useEffect(() => {
    if (y < decreasePoint[0]) {
      return setOpacity(0);
    }

    if (y > decreasePoint[1]) {
      setOpacity(
        increaseOpacity({
          _value: y - decreasePoint[1],
          _total: decreasePoint[1] - decreasePoint[0],
        })
      );
    }
  }, [y, vh, decreasePoint, increaseOpacity, setOpacity]);

  /**
   * 스크롤 이벤트 높이 구하는 함수
   * @type {function(): *}
   */
  const getHeight = useCallback(() => {
    return decreasePoint[1] + vh;
  }, [decreasePoint, vh]);

  const fill = '#333';

  return (
    <>
      <MainHeader opacity={opacity} />
      <Visual opacity={opacity} />
      <div className="content-wrap--opacity">
        {SCROLL_CONTENT_LIST.map((content, index) => (
          <div
            key={`opacity-box-${index}`}
            style={{
              position: 'sticky',
              top: 0,
              textShadow: '2px 2px 4px #000',
            }}
          >
            <OpacityBox index={index} content={content}>
              {content.component}
            </OpacityBox>
          </div>
        ))}
      </div>

      <div
        className="content-wrap"
        style={{
          position: 'relative',
          zIndex: 1,
          padding: `${getHeight()}px 0 0`,
          backgroundColor: `rgba(255, 255, 255, ${opacity})`,
        }}
      >
        <div
          style={{
            position: 'relative',
            '--center-box-height': 'unset',
            '--center-box-font-color': '#333',
          }}
        >
          <AlignCenter>
            <AlignCenter.Before>빅브라운의 첫번째 솔루션</AlignCenter.Before>
            <IconLogo fill={fill} />
            <AlignCenter.After>
              {[
                '글로벌 OOH광고 직거래 플랫폼 ‘HOO’',
                '',
                '전 세계 OOH광고매체의',
                '최신 정보, 손쉬운 검색, 문의, 계약까지',
                '플랫폼 내에서 모든 서비스를 제공합니다.',
              ].map((item, index) => (
                <div key={`${item}-${index}`}>{item}</div>
              ))}
            </AlignCenter.After>
          </AlignCenter>
        </div>

        <div
          style={{
            position: 'relative',
            '--center-box-font-color': '#333',
            '--center-box-background-color': '#fafafa',
          }}
        >
          <AlignCenter>
            <AlignCenter.Before>WHY HOO(House Of OOH)</AlignCenter.Before>
            변화를 넘어 혁신으로
            <AlignCenter.After>
              {[
                '현재의 옥외광고 시장의 문제점을',
                '디지털 전환(DIGITAL TRANFORMATION)을 ',
                '통해 해결하고 빅 데이터와 AI기술을 접목하여 ',
                '디지털 혁신(DIGITAL INNOVATION)을 이루어 냅니다.',
              ].map((item, index) => (
                <div key={`${item}-${index}`}>{item}</div>
              ))}
            </AlignCenter.After>
          </AlignCenter>
        </div>

        <div>
          <Greeting />
        </div>

        <div>
          <Footer />
        </div>
      </div>
    </>
  );
}

HomeContents.propTypes = {};
HomeContents.defaultProps = {};

export default HomeContents;
